import { async } from '@firebase/util';
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
// utils
import axiosInstance from '../../utils/axios';
//
import { dispatch } from '../store';
// config

const appKey = 'L2tPsd3PJ26RQwuaQvNVyhabWqVcdE';

const initialState = {
  isLoading: false,
  filter: {
    // academicYear: '',
    academicYear: null,
    year: '',
  },
  dataPaymentTopSchool: [],
  dataPaymentAllSchool: [],
  adminAcademicdate: [],
  setDataRecord: [],
};

const slice = createSlice({
  name: 'totalPayment',
  initialState,
  reducers: {
    resetData(state) {
      state.dataPaymentAllSchool = [];
      state.dataPaymentTopSchool = [];
    },
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setFilter(state, action) {
      state.isLoading = false;
      state.filter = action.payload;
    },
    getDataPayment(state, action) {
      state.isLoading = false;
      state.dataPaymentTopSchool = action.payload;
    },
    getDataPaymentAllSchool(state, action) {
      state.isLoading = false;
      state.dataPaymentAllSchool = action.payload;
    },
    getAdminAcademicdate(state, action) {
      state.isLoading = false;
      state.adminAcademicdate = action.payload;
    },
    getSchoolRecord(state, action) {
      state.isLoading = false;
      state.setDataRecord = action.payload;
    },
  },
});

export function setFilterSuccess(params) {
  return async (dispatch) => {
    dispatch(slice.actions.setFilter(params));
  };
}
export function ResetData(params) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    dispatch(slice.actions.resetData(params));
  };
}

export function getDataPaymentSuccess(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const res = await axiosInstance.post(`get-all-guardian-payment-list`, params, {
        headers: {
          APPKEY: appKey,
        },
      });
      console.log(res);
      if (res?.data?.data) {
        dispatch(slice.actions.getDataPayment(res?.data?.data));
      }
      // return res;
      return res;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
      return error;
    }
  };
}
export function getDataPaymentAllSchoolSuccess(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const res = await axiosInstance.post(`get-all-guardian-payment-chart`, params, {
        headers: {
          APPKEY: appKey,
        },
      });
      console.log(res);
      if (res?.data?.data) {
        dispatch(slice.actions.getDataPaymentAllSchool(res?.data?.data));
      }
      // return res;
      return res;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
      return error;
    }
  };
}

export function adminAcademicdateSuccess() {
  return async () => {
    try {
      const response = await axiosInstance.get(`get-all-admin-academicdate`, {
        headers: {
          APPKEY: 'L2tPsd3PJ26RQwuaQvNVyhabWqVcdE',
        },
      });
      if (response?.data?.data) {
        dispatch(slice.actions.getAdminAcademicdate(response?.data?.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log('error', error);
    }
  };
}

export function getSchoolRecord(param) {
  return async () => {
    try {
      const response = await axiosInstance.post(`summary-data-by-schoolyear`, param, {
        headers: {
          APPKEY: 'L2tPsd3PJ26RQwuaQvNVyhabWqVcdE',
        },
      });
      // console.log(response?.data?.data)
      if (response?.data?.data) {
        dispatch(slice.actions.getSchoolRecord(response?.data?.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log('error', error);
    }
  };
}

export default slice.reducer;
