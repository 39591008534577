const kh = {
  demo: {
    title: `Khmer`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  HEADER: {
    TITLE: `ប្រព័ន្ធខេមមីស`,
  },
  SCHOOL_DATA: {
    TITLE: 'ទិន្នន័យសាលា',
    SCHOOL: 'សូមជ្រើសរើសសាលារៀន',
    ACADEMIC_YEARS: 'សូមជ្រើសរើសឆ្នាំសិក្សា',
    ENTER_LEVEL: 'សូមបញ្ចូលកម្រិតសិក្សា',
    ENTER_YEARS: 'សូមបញ្ចូលឆ្នាំសិក្សា',
    ACADEMIC_YEAR: 'ឆ្នាំសិក្សា',
    LEVEL: 'កម្រិតសិក្សា',
    CLASS: 'ថ្នាក់រៀន',
    LAST_NAME: 'គោត្តនាម',
    FIRST_NAME: 'នាម',
    LAST_NAME_EN: 'គោត្តនាម (ឡាតាំង)',
    FIRST_NAME_EN: 'នាម​ (ឡាតាំង)',
    ENTER_CODE: 'សូមបញ្ចូលលេខកូដ',
    STUDENT: {
      CODE: 'កូដ',
      CLASSROOM: 'ថ្នាក់រៀន',
      DOB: 'ថ្ងៃខែឆ្នាំកំណើត',
      PHONE_NUMBER: 'លេខទូរស័ព្ទ',
      NOT_GUARDIAN: 'មិនទាន់ភ្ជាប់អាណាព្យាបាល',
      S_LAST_NAME: 'គោត្តនាមសិស្ស',
      S_FIRST_NAME: 'នាមសិស្ស',
      S_CODE: 'លេខកូដសិស្ស',
      S_LIST: 'ព័ត៌មានសិស្ស',
      MAZER_CLASS: 'ប្រធានថ្នាក់',
      MAZER: 'ប្រធាន',
      FULLNAME_LATIN: 'ឈ្មោះឡាតាំង',
    },
    GUARDIAN: {
      INFORMATION: 'ព័ត៌មានអាណាព្យាបាល',
      GUARDIAN: 'អាណាព្យាបាល',
      EMPTY_DATA: 'ពុំមានទិន្នន័យ',
      CODE: 'កូដ',
      PHONE_NUMBER: 'លេខទូរស័ព្ទ',
      DOB: 'ថ្ងៃខែឆ្នាំកំណើត',
      EXPIRED: 'ថ្ងៃផុតកំណត់',
      G_LAST_NAME: 'គោត្តនាមអាណាព្យាបាល',
      G_FIRST_NAME: 'នាមអាណាព្យាបាល',
      G_CODE: 'លេខកូដអាណាព្យាបាល',
      G_LIST: 'ព័ត៌មានអាណាព្យាបាល',
    },
    STAFF: {
      FULLNAME_LATIN: 'ឈ្មោះឡាតាំង',
      CODE: 'កូដ',
      PHONE_NUMBER: 'លេខទូរស័ព្ទ',
      DOB: 'ថ្ងៃខែឆ្នាំកំណើត',
    },
  },
  BUTTON: {
    SEARCH: 'ស្វែងរក',
    OK: 'យល់ព្រម',
    DETAIL: 'លម្អិត',
    RESET_PASSWORD: 'ប្ដូរឡើងវិញ',
    CREATE: 'បង្កើត',
    CONNECT_GUARDIAN: 'ភ្ជាប់អាណាព្យាបាលទៅកាន់សិស្ស',
    EXCEL: 'នាំចេញទៅកាន់ Excel',
    EXCEL_PAYMENT_ALL_SCHOOL: 'ទិន្នន័យបង់លុយអាណាព្យាបាលគ្រប់សាលា',
    EXCEL_PAYMENT_ALL_SCHOOL_Backup: 'ទិន្នន័យបង់លុយអាណាព្យាបាលគ្រប់សាលា backup',
    DATA_STUDENT: 'ទិន្នន័យសិស្ស',
    DATA_GUARDIAN: 'ទិន្នន័យអាណាព្យាបាល',
    SHOW_DATA: 'បង្ហាញទិន្នន័យ',
    PRINT_PDF: 'នាំចេញទៅកាន់​ PDF',
    ASSIGN_MAZER: 'ចាត់តាំងប្រធានថ្នាក់',
    DELETE: 'លុប',
    REMOVE: 'ផ្លាស់ប្ដូរ',
    CANCEL: 'ត្រឡប់ក្រោយ',
    FILTER: 'ស្វែងរកឈ្មោះ',
    SAVE_CHANGE: 'ផ្លាស់ប្ដូរ',
    EDIT: 'កែប្រែ',
    ADD_MORE: 'បង្តើតថ្មី',
    SAVE: 'រក្សាទុក',
    DOWNLOAD: 'ទាញយកជា PDF',
  },
  EMPTY_CONTENT: {
    CONTENT: 'សូមបញ្ចូលព័ត៌មានខាងលើ!',
    CONTENT_GENERAL: 'សូមបញ្ចូលព័ត៌មានខាងលើ!',
    NOT_DATA: 'មិនមានទិន្នន័យ',
    DATA_LOADING: 'កំពុងស្វែងរក',
  },
  CHECK_BOX: {
    CHOOSE: 'សូមជ្រើសរើស',
  },
  EXCEL: {
    ENTER_YEAR: 'សូមបញ្ចូលឆ្នាំសិក្សា',
    CHOOSE_MONTH: 'សូមជ្រើសរើសខែ',
    DATE: 'ថ្ងៃ​ ខែ ឆ្នាំ',
    START_DATE: 'ចាប់ផ្ដើម : ថ្ងៃ​ ខែ ឆ្នាំ',
    END_DATE: 'បញ្ចប់​ : ថ្ងៃ​ ខែ ឆ្នាំ',
    DATA_PAYMENT_BY_SCHOOL_ONCE_DAY: 'ទិន្នន័យបង់លុយអាណាព្យាបាលតាមសាលា(មួយថ្ងៃ)',
    DATA_PAYMENT_BY_SCHOOL_ONCE_DAY_BACKUP: 'ទិន្នន័យបង់លុយអាណាព្យាបាលតាមសាលា backup(មួយថ្ងៃ)',
    DATA_PAYMENT_BY_SCHOOL_MORE_MONTH: 'ទិន្នន័យបង់លុយអាណាព្យាបាលតាមសាលា(ជ្រើសរើសបានច្រើនខែ)',
    DATA_PAYMENT_BY_SCHOOL_MORE_MONTH_BACKUP: 'ទិន្នន័យបង់លុយអាណាព្យាបាលតាមសាលា backup(ជ្រើសរើសបានច្រើនខែ)',
    STATISTIC: 'ស្ថិតិសរុប',
    TOTAL_SCHOOL: 'ចំនួនសាលា',

    TOTAL: 'សរុបរួម',
    SCHOOL_NAME: 'ឈ្មោះសាលា',
    STUDENT: 'សិស្ស',
    TOTAL_USED: "ចំនួនប្រើប្រាស់",
    PERCENTAGE: "ភាគរយ",

    TOTAL_DATA: 'ទិន្នន័យ',
    TOTAL_DATA_ACTIVE: 'ចំនួនអ្នកប្រើប្រាស់',
    TOTAL_DATA_PERCENTAGE: 'ភាគរយ',

    TOTAL_STUDENT: 'សិស្សានុសិស្ស',
    TOTAL_ACTIVE_STUDENT: 'សិស្សប្រើប្រាស់',
    RATIO_STUDENT: 'ភាគរយសិស្សសរុប',

    TOTAL_GUARDIAN: 'អាណាព្យាបាល',
    TOTAL_ACTIVE_GUARDIAN: 'អាណាព្យាបាលប្រើប្រាស់',
    RATIO_GUARDIAN: 'ភាគរយអាណាព្យាបាលសរុប',

    TOTAL_TEACHER: 'រដ្ឋបាលសាលា',
    TOTAL_ACTIVE_TEACHER: 'ចំនួនប្រើប្រាស់',
    RATIO_TEACHER: 'ភាគរយរដ្ឋបាលសរុប',

    TOTAL_USER: 'ទិន្នន័យ',
    TOTAL_ACTIVE_USER: 'អ្នកប្រើប្រាស់សរុប',
    RATIO_USER: 'ភាគរយទិន្នន័យប្រើប្រាស់សរុប',
  },
  REVENUE: {
    ACADEMIC_YEAR: 'សូមជ្រើសរើសឆ្នាំសិក្សា',
    EVENT_ID: 'សូមជ្រើសរើស ថ្ងៃ ខែ ឆ្នាំ​ ',
    EVENT_NAME: 'សូមបញ្ចូលឈ្មោះ ',
    YEAR: 'សូមជ្រើសរើសឆ្នាំ',
    TOP_SCHOOL: {
      TABLE_TOP: 'លំដាប់សាលា',
      LINE: 'គំនូសតាងបន្ទាត់',
      N0: 'លេខរៀង',
      TOP: 'លំដាប់សាលា',
      QTY: 'បរិមាណសរុប',
      REVENUES: 'ចំណូលសរុប',
      PERCENT: 'ភាគរយ​ %',
      MONTH: 'ប្រចាំខែ',
      SEMESTER: 'ប្រចាំឆមាស',
      YEAR: 'ប្រចាំឆ្នាំ',
      STUDENT_AMOUNT: 'ចំនួនសិស្សសរុប',
    },
  },
  MODAL: {
    ASSIGN_CLASS_MONITOR: 'តើអ្នកចង់ចាត់តាំងប្រធានថ្នាក់មែនទេ?',
    REMOVE_CLASS_MONITOR: 'តើអ្នកចង់ប្ដូរប្រធានថ្នាក់ចេញមែនទេ?',
    CLASS_MONITOR: 'ប្រធានថ្នាក់',
    PRICE_PAYMENT: 'តម្លៃបង់ប្រាក់',
    TYPE_PAYMENT: 'ប្រភេទបង់',
    MONTH: 'ខែ',
    COMMISION_PLAN: 'បង្កើតផែនការភាគលាភ',
    DELETE_COMMISION_PLAN: 'តើអ្នកចង់លុប ',
    TITLE_COMMISION_PLAN: 'ភាគលាភ',
    EDIT_GUARDIAN: 'កែងអាណាព្យាបាល',
    EDIT_Staff: 'កែងបុគ្គលិក',
  },
  FEE_SETTING: {
    TABLE: {
      PRICE: 'តម្លៃ',
      OPTION: 'ជម្រើស',
      ACTION: 'សកម្មភាព',
    },
    COMMISION_PLAN: {
      NAME: 'ឈ្មោះ',
      PERCENT: 'ភាគរយ',
      ACTION: 'សកម្មភាព',
    },
    TEACHER_COMMISION: {
      SELECT_TEACHER: 'សូមជ្រើសរើសឈ្មោះគ្រូ',
      SELECT_STUDENT: 'សូមជ្រើសរើសឈ្មោះសិស្ស',
      SELECT_METHOD: 'សូមជ្រើសរើសវិធីសាស្ត្រនៃការបង់',
      SELECT_OPTION: 'សូមជ្រើសរើសប្រភេទនៃការបង់',
    },
  },
  BACK_UP_MONEY: {
    CARD_CREATE_BACK_UP: {
      CREATE_BACK_UP: 'បង្កើតប្រតិបត្តិការ',
    },
    CARD_SHOW_BACK_UP: {
      SHOW_BACK_UP: 'បន្ទាប់ពីការ backup ចំណូល',
      AFTER_BACK_UP: 'ចំណូលដែលបាន backup',
      PERCENT: 'ភាគរយ',
      //
      REAL_TOTAL_GUARDIAN: 'អាណាព្យាបាលពិត',
      REAL_TOTAL_GUARDIAN_PAID: 'ចំណូលពិត',
      WITHDRAWAL: 'ចំណូលដកចេញ',
      MONTHLY: 'ដកអ្នកបង់ខែ',
      SEMESTER: 'ដកអ្នកបង់ឆមាស',
      YEARLY: 'ដកអ្នកបង់ឆ្នាំ',
      FAKE_TOTAL_GUARDIAN: 'អាណាព្យាបាលមិនពិត',
      FAKE_TOTAL_PAID: 'ចំណូលមិនពិត',
    },
    BAR_CHART: {
      REAL_TOTAL_INCOME: 'ចំណូលពិត',
      FAKE_TOTAL_INCOME: 'ចំណូល backup',
      //
      REAL_CASH: 'ចំណូល || ពិត',
      FAKE_CASH: 'ចំណូល || មិនពិត',
    },
    BUTTON: {
      GENERATE: 'គណនា',
    },
  },
  REQUEST_SCHOOL: {
    LIST_REQUEST: {
      LIST_REQUEST_FREE: 'បញ្ជីស្នើសុំប្រើប្រាស់ free',
      NO: 'ល.រ',
      GUARDIAN_FULLNAME: 'ឈ្មោះអាណាព្យាបាល',
      STUDENT_FULLNAME: 'ឈ្មោះសិស្ស',
      SITUATION: 'ស្ថានភាព',
      REQUEST_BY: 'ស្នើសុំដោយ',
      REFERENCE: 'ឯកសារយោង',
      CHOOSE: 'ជ្រើសរើស',
      CLASS: 'ថ្នាក់',
    },
    LIST_APPROVED: {
      LIST_APPROVED_FREE: 'បញ្ជីបានអនុញ្ញាតអោយប្រើ free',
      STATUS: 'ស្ថានភាព',
    },
    LIST_REJECTED: {
      LIST_REJECTED_FREE: 'បញ្ជីមិនអនុញ្ញាតអោយប្រើ free',
      STATUS: 'ស្ថានភាព',
    },
    LIST_CASE: {
      CASE: 'ករណី',
      PAYMENT_METHOD: 'ករណី',
      PERIOD: 'រយៈពេល',
      DATE: 'ការបរិច្ឆេទ',
      PRINT: 'បោះពុម្ព',
      EXPORT_TO_EXCEL: 'នាំចេញទៅកាន់ Excel',
      NUMBER_CHILD: 'ចំនួនកូន',
      DOB: 'ថ្ងៃខែឆ្នាំកំណើត',
      GENDER: 'ភេទ',
    },
  },
};

export default kh;
